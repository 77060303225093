.nav-tabs{
    border-bottom: 0;    

    .nav-item{
        .nav-link{
            position: relative;
            background-color: transparent;
            text-transform: uppercase;
            font-weight: $weight-bold;
            font-size: 1.05rem;
            padding: .3em .7em;
            border-radius: 0;
            border: 0;
            color: $gray;
            &::after{
                @include position(absolute, auto, 0.7em, 0.3em, 0.7em);
                @include opacity(0);
                transform: scaleX(0);
                transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                content: '';
                border-bottom: 2px solid;
            }
            @include hover-state {
                border: 0;
                &::after{
                    @include opacity(1);
                    transform: scaleX(1);
                }
            }
            &.active{
                border: 0;
                color: #000000;
                &::after{
                    @include opacity(1);
                    transform: scaleX(1);
                }
            }
        }
    }
}