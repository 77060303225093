.footer{
    background-color: $brand-primary;
    padding: 40px 0;

    @include media-breakpoint-down(xs) {
        .footer-logo-columm{
            margin-bottom: 1rem;
        }
    }
    @include media-breakpoint-down(sm) {
        .column{
            margin-bottom: 20px;
        }
    }
    .footer-menu{
        .footer-menu-title{
            color: $brand-secondary;
            text-transform: uppercase;
            font-weight: $weight-bold;
        }
        .menu{
            list-style: none;
            margin: 0;
            padding: 0;

            li{
                padding: 4px 0;
                a{
                    padding-right: 7px;
                    font-weight: $weight-medium;
                    font-size: 0.8rem;
                    color: $brand-secondary;
                    text-decoration: none;
                    @include hover-state {
                        text-decoration: underline;
                    }
                }
                &:first-child{
                    margin: 0;
                    a:before{
                        content: "";
                        padding: 0;
                    }
                }
            }
        }
        .textwidget{
            color: $brand-secondary;
            p{
                margin-bottom: 0.5rem;
            }
            .social-icon{
                margin-right: 10px;
                font-size: 1.9rem;
                display: inline-block;
                transition: all 0.3s ease-in;
                line-height: 0;
                color: $gray;
                @include hover-state {
                    color: #ffffff;
                }
            }
        }
        .footer-logo{
            @include size(170px, auto);
        }
    }
}

.footer-bottom{
    @include size(100%, auto);
    background-color: #ffffff;
    padding: 10px 0;
    letter-spacing: 1px;
    text-align: center;

    .container{
        .credit{
            font-weight: $weight-regular;
            color: $gray-dark;
            font-size: 0.7rem;
        }
    }
}

.ikea-status {
    @include position(fixed, auto, 15px, 15px, auto);
    border-radius: 2rem;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, .1);
    color: yellow;
    font-weight: $weight-bold;
    z-index: 2;

    &:after{
        @include backdrop-blur(#407ab1, #407ab1, 0.75, 1);
        border-radius: 2rem;
    }

    .ikea-status-label{
        padding: 1rem 1.5rem;
    }
    .ikea-status-logout{
        @include size(57px);
        position: relative;
        display: inline-block;
        vertical-align: middle;
        transition: all 0.25s ease-in;
        border-radius: 50%;
        z-index: 2;

        @include hover-state {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            transform: scale(1.04);
        }

        &:after{
            @include backdrop-blur(#407ab1, darken(#407ab1, 5%), 0.3, 1);
            font-family: 'FontAwesome';
            content: '\f08b';
            color: #ffffff;
            border-radius: 50%;
            line-height: 57px;
            text-align: center;
            font-size: 1.2rem;
        }
    }

    @include media-breakpoint-down(xs) {
        @include position(fixed, auto, 0, 0, 0);
        display: flex;
        text-align: center;
        border-radius: 0;

        &:after{
            border-radius: 0;
        }

        .ikea-status-label{
            padding: 1rem;
            flex: 1;
        }
        .ikea-status-logout{
            flex: 0 0 57px;
            border-radius: 0;

            &:after{
                border-radius: 0;
            }
        }
    }
}
