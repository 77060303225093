.woocommerce {
    .woocommerce-message, 
    .woocommerce-warning,
    .woocommerce-error,
    .woocommerce-success,
    .woocommerce-info{
        border: 0;
        background-color: $brand-primary;
        border-radius: 2rem;
        margin-bottom: 1em;
        line-height: 2;
        color: #ffffff;
        a.woocommerce-Button.button{
            border: 0;
            outline: 0;
            cursor: pointer;
            transition: all 0.3s ease-in;
            background-color: #ffffff;
            text-transform: uppercase;
            color: #111111;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
            border-radius: 1.5rem;
            font-size: 0.7rem;
            padding: 0.55rem 1.4rem;
        }
        a{
            color: #ffffff;
        }
        &:before{
            line-height: 1;
            font-size: 17px;
            top: 1.2em;
            color: currentColor;
        }
    }
    .woocommerce-info{
        background-color: $brand-info;
    }
    .woocommerce-warning{
        background-color: $brand-warning;
    }
    .woocommerce-error{
        background-color: $brand-danger;
    }
    .woocommerce-succes{
        background-color: $brand-success;
    }
    .woocommerce-message{
        &:before{
            transform: translateY(-50%);
            top: 50%;
            color: currentColor;
        }
    }
}