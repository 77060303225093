.btn {
    font-size: 0.9rem;
    text-transform: uppercase;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: all 0.3s ease-in;
    padding: .8rem 1.8rem;
    
    @include hover-state {
        outline: 0 !important;
        box-shadow: none;
    }

    &.btn-primary{
        text-transform: uppercase;
        color: $brand-secondary;
        @include hover-state {
            color: $brand-secondary;
        }
    }
    &.btn-primary-light{
        background-color: lighten($brand-primary, 30%);
        color: $brand-primary;
        @include hover-state {
            background-color: lighten($brand-primary, 20%);
            color: $brand-primary;
        }
    }
    &.btn-white{
        background-color: #ffffff;
        text-transform: uppercase;
        padding: .8rem 2.5rem;
        color: #111111;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);

        @include media-breakpoint-down(xs) {
            padding: .8rem 2rem;
        }
        @include hover-state {
            background-color: #000000;
            box-shadow: 0 4px 3px rgba(0, 0, 0, 0.12);
            color: #ffffff !important;
        }
    }
    &.btn-text {
        background-color: transparent;
        text-transform: none;
        color: $brand-primary;
        font-weight: $weight-regular;
        padding: 0.2rem 0;
        @include hover-state {
            color: darken($brand-primary, 5%);
        }
    }
    &.btn-transparent {
        background-color: transparent;
        border-radius: 0;
        color: $brand-secondary;
        @include hover-state {
            color: $brand-secondary;
        }
    }
    &.full {
        width: 100%;
    }
    &.btn-lg{
        font-size: 1rem;
    }
    &.btn-sm{
        font-size: 0.7rem;
        padding: 0.55rem 1.4rem;
    }

}