.woocommerce{
    nav.woocommerce-pagination{
        padding: 0;
        display: block;
        text-align: left !important;
        line-height: 0;
        margin-top: 1rem;
        width: 100%;
        overflow-x: auto;

        ul{
            border: 0;
            margin: 0;
            li{
                border: 0;
                margin-right: 10px;
                float: none;
                &:last-child{
                    margin-right: 0;
                }
                span, a{
                    background: transparent;
                    display: inline-block;
                    padding: 0.5rem 0.3rem;
                    font-size: 0.8rem;
                    font-weight: $weight-medium;
                    text-decoration: none;
                    color: $gray-dark;

                    @include hover-state {
                        background: transparent;
                        color: #000000;
                    }
                    &.current{
                        background: #000000;
                        color: #ffffff;
                        font-size: 1rem;
                    }
                    &.prev, &.next{
                        color: #000000;
                        padding: 0.6rem;
                        &:after{
                            @include size(15px, 15px);
                            font-family: "FontAwesome";
                            content: "";
                            vertical-align: middle;
                            display: inline-block;
                        }
                    }
                    &.prev{
                        &:after{
                            content: "\f053";
                        }
                    }
                    &.next{
                        &:after{
                            content: "\f054";
                        }
                    }
                }
            }
        }
    }
}