.storelocator-module{
    &.mini{
        #wpsl-wrap.wpsl-default-filters{
            .wpsl-gmap-canvas{
                @include responsive-ratio(8, 5, true);
                max-height: 400px;
            }
            #wpsl-result-list{
                #wpsl-result-list-container{
                    padding: 2rem 2rem 2rem 15px;
                    #wpsl-stores{
                        max-height: 225px;
                        ul{
                            max-height: 225px;
                        }
                    }
                }
            }
        }
    }
    #wpsl-wrap.wpsl-default-filters, #wpsl-wrap.wpsl-mobile{
        display: flex;
        margin-bottom: 0;
        background-color: $gray-lightest;
        position: relative;

        &:before{
            @include position(absolute, 50%, auto, auto, 50%);
            @include square(40px);
            @include opacity(1);
            transform: translate(-50%, -50%);
            animation: rotating 1.5s linear infinite;
            transform-origin: center center;
            font-family: 'FontAwesome';
            content: "\f110";
            line-height: 40px;
            text-align: center;
            font-size: 2rem;
            color: $brand-primary;
            transition: all 0.3s ease-in;
        }

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }

        .wpsl-gmap-canvas{
            flex: 1 1 65%;
            width: auto;
            height: auto !important;
            float: none;
            position: static;
            margin: 0;
            order: 2;
            transition: all 0.4s ease-in;
            @include opacity(0);
            @include responsive-ratio(4, 3, true);

            @include media-breakpoint-down(sm) {
                flex: 1 0 100%;
                order: 0;
            }
        }
        #wpsl-result-list{
            display: flex;
            justify-content: flex-end;
            flex: 1 1 35%;
            width: auto;
            margin: 0;
            float: none;
            transition: all 0.4s ease-in;
            @include opacity(0);

            @include media-breakpoint-down(sm) {
                justify-content: center;
                flex: 1 0 100%;
            }
            @include media-breakpoint-down(xs) {
                justify-content: flex-start;
            }

            #wpsl-result-list-container{
                width: 100%;
                max-width: 400px;
                padding: 3.5rem 2rem 2rem 15px;
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(xs) {
                    max-width: 100%;
                }
                .wpsl-content-box{
                    margin-bottom: 15px;
                    p{
                        padding: 0;
                        margin-bottom: 0;
                    }
                }
                #wpsl-search-wrap{
                    float: none;
                    form{
                        position: relative;
                        div, #wpsl-search-btn{
                            float: none;
                        }
                        div label{
                            display: none;
                        }
                        .wpsl-input{
                            width: 100%;
                            margin: 0;
                            
                            #wpsl-search-input{
                                @include size(100%, auto);
                                border-radius: 20px;
                                padding: 10px 70px 10px 15px;
                                font-size: 90%;
                                line-height: normal;
                                transition: all 0.3s ease-in;

                                @include media-breakpoint-down(xs) {
                                    width: 100% !important;
                                }

                                &:focus{
                                    padding: 12px 70px 12px 15px;
                                }
                            }
                        }
                        .wpsl-search-btn-wrap{
                            @include position(absolute, 2px, 2px, 2px, auto);
                            margin: 0;
                            #wpsl-search-btn{
                                @extend .btn;
                                @extend .btn-secondary;
                                height: 100%;
                                margin: 0;
                                border-radius: 20px;
                                line-height: normal;
                                font-size: 0.7rem;
                                padding: 0 1.2rem;
                                background-image: none;
                                text-transform: uppercase !important;
                                @include hover-state {
                                    padding: 0 1.5rem;
                                }
                            }
                        }
                    }
                }
                #wpsl-stores{
                    margin: 10px 0 0 0;
                    position: relative;

                    &:before, &:after{
                        content: "";
                        @include size(100%, 1.2rem);
                        z-index: 10;
                    }
                    &:before{
                        @include position(absolute, 0, 0, auto, -8px);
                        background: -webkit-linear-gradient(to top, hsla(0, 0%, 100%, 0), $gray-lightest 50%);
                        background: linear-gradient(to top, hsla(0, 0%, 100%, 0), $gray-lightest 50%);
                    }
                    &:after{
                        @include position(absolute, auto, 0, 0, -8px);
                        background: -webkit-linear-gradient(to top, $gray-lightest 50%, hsla(0, 0%, 100%, 0));
                        background: linear-gradient(to top, $gray-lightest 50%, hsla(0, 0%, 100%, 0));
                    }

                    ul{
                        height: 100% !important;
                        max-height: 550px;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        -ms-overflow-style: -ms-autohiding-scrollbar;
                        padding: 1rem 0;

                        @include scrollbar(7px, $gray-lighter, $brand-primary);

                        li{
                            padding: 0;
                            border-bottom-style: solid;
                            border-color: $gray-lighter;
                            padding: 10px 0;
                            transition: all 0.3s ease-in;
                            cursor: pointer;  

                            @include hover-state {
                                @include opacity(0.7);
                                padding: 10px;
                            }
                            &:first-child{
                                padding-top: 0;
                            }
                            &:last-child{
                                border-bottom: 0;
                                padding-bottom: 0;
                            }
                            &.no-result{
                                display: inline-block;
                            }
                            &.wpsl-preloader{
                                font-size: 0;
                                line-height: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100px;
                                img{
                                    @include square(15px);
                                    position: static;
                                    top: 0;
                                    margin: 0;
                                    display: block;
                                }
                            }
                            .wpsl-store-location{
                                p{
                                    margin-bottom: 5px;
                                }
                                strong{
                                    display: block;
                                    color: #000000;
                                }
                                .wpsl-country{
                                    display: inline-block;
                                }
                            }
                            .wpsl-direction-wrap{
                                .wpsl-directions{
                                    display: inline-block;
                                }
                                .wpsl-store-choose-store{
                                    font-weight: bold;
                                    padding-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.show{
            &:before{
                @include opacity(0);
            }
            #wpsl-gmap, #wpsl-result-list{
               opacity: 1 !important;
            }
        }
    }
}