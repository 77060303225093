.search-form{
    position: relative;
    .search-field{
        border: 0;
        color: $gray-dark;
        background-color: $gray-lighter;
        text-indent: 20px;
        font-size: 0.75rem;
        line-height: 1rem;
        @include placeholder {
            color: $gray;
        }
    }
    .search-submit{
        @include square(14px);
        @include position(absolute, 0, auto, auto, 5px);
        background-color: transparent;
        color: #ffffff;
        padding: 0;
        .icon{
            color: #000000;
            font-size: 1rem;
        }
    }
}