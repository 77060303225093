.woocommerce-account{
    .main-content .container{
        padding: 1.5rem 15px;
        @include media-breakpoint-down(xs) {
            padding: 0;
        }
    }
    .main{
        flex: 0 1 900px;
        @include media-breakpoint-down(xs) {
            border-radius: 0;
            box-shadow: none;
        }

        > .woocommerce{
            padding: 1.5rem;
        }
    }
    #my-account-menu-tab{
        @include size(100%, auto);
        @include center-cover();
        background-color: $brand-primary;
        position: relative;
        padding: 1.5rem 1.5rem 0 1.5rem;
        border-radius: $border-radius $border-radius 0 0;

        @include media-breakpoint-down(xs) {
            border-radius: 0;
            padding: 0;
        }

        .user-profile{
            display: flex;
            padding: 0;

            @include media-breakpoint-down(sm) {
                padding: 1rem 1rem 1.5rem 1rem;
            }

            #load-avatar{
                border-radius: 50%;
            }
            .user-image{
                @include square(50px);
                flex: 0 0 50px;
                float: none;
                margin: 0;
                overflow: hidden;
                img{
                    width: 100%;
                    background-color: #ffffff;
                    border-radius: 50%;
                    overflow: hidden;
                    border: 2px solid #ffffff;
                }
                a{
                    text-decoration: none;
                }
            }
            .user-info{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 1 1 100%;
                padding: 0;
                margin-left: 0.7rem;

                .username{
                    flex: 1 1 100%;
                    font-size: 1.4rem;
                    margin: 0;
                    color: #ffffff;
                }
                .logout{
                    flex: 0 0 120px;
                    a{
                        font-size: 0.9rem;
                        text-transform: uppercase;
                        border: 0;
                        outline: 0;
                        cursor: pointer;
                        transition: all 0.3s ease-in;
                        padding: .8rem 1.8rem;
                        float: right;   
                        background-color: #ffffff;
                        line-height: 1;
                        text-transform: uppercase;
                        padding: .8rem 2.5rem;
                        border-radius: $border-radius;
                        color: #111111;
                        box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
                        font-weight: $weight-bold;

                        @include media-breakpoint-down(xs) {
                            padding: .8rem 2rem;
                            text-decoration: none;
                        }
                        @include hover-state {
                            background-color: #000000;
                            box-shadow: 0 4px 3px rgba(0, 0, 0, 0.12);
                            color: #ffffff !important;
                        }      
                    }
                }
            }      
        }
        .myaccount-menu{
            margin: 1.5rem auto 0 auto;
            border: 0;
            text-align:center;
            list-style: none;
            padding: 0;
            border-bottom: 0 !important;
            display: flex;

            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }

            > li{
                margin: 0 5px 0 0 !important;
                border: 0 !important;
                -ms-flex-negative: 0;
                flex-shrink: 0;

                a{
                    color: #ffffff;
                    padding: 10px 15px;
                    font-weight: 500;
                    transition: all 0.2s ease-in;
                    border: 0;
                }
                &.active a, &:hover a, &:focus a{
                    border-radius: 5px 5px 0 0;
                    background-color: #ffffff;
                    color: $brand-primary;
                }
            }
        }

        &.scroll{
            &:before, &:after{
                @include size(30px, 43px);
                @include position(absolute, auto, auto, 0, auto);
                pointer-events: none;
                z-index: 10;
                transition: opacity 0.4s ease-in-out;
                -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                filter: alpha(opacity=100);
                line-height: 43px;
                font-size: 20px;
                color: #ffffff;
                display: block;
                opacity: 1;
            }
            &:before{
                left: 0;
                background: -webkit-linear-gradient(to left, rgba(darken($brand-primary, 10%), 0), darken($brand-primary, 5%) 68%);
                background: linear-gradient(to left, rgba(darken($brand-primary, 10%), 0), darken($brand-primary, 5%) 68%);
                content: '<';
                text-align: left;
                padding-left: 5px;
            }
            &:after{
                right: 0;
                background: -webkit-linear-gradient(to left, darken($brand-primary, 10%) 32%, rgba(darken($brand-primary, 20%), 0));
                background: linear-gradient(to left, darken($brand-primary, 10%) 32%, rgba(darken($brand-primary, 5%), 0));
                content: '>';
                text-align: right;
                padding-right: 5px;
            }
            &.left-end:before, &.right-end:after{
                opacity: 0;
            }

            .myaccount-menu{
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: -ms-autohiding-scrollbar;
                &::-webkit-scrollbar { 
                    display: none; 
                }

                > li{
                    margin: 0 !important;
                    float: left !important;
                    text-align: center;
                    border: 0;
                    border-radius: 0 !important;

                    &:first-child{
                        padding-left: 20px;
                    }
                    &:last-child{
                        padding-right: 20px;
                    }

                    a{
                        border: 0;
                        border-radius: 0;
                        background-color: transparent;
                        color: #ffffff;
                        font-weight: $weight-bold;
                        @include hover-state {
                            border: 0;
                        }
                    }
                }
            }
        }
    }

    #my-account-content{
        width: 100%;
        padding: 1.5rem;
        background-color: #ffffff;
        border-radius: 0 0 $border-radius $border-radius;

        .table{
            border: 0;
            border-collapse: collapse;
        }
        .woocommerce{
            .woocommerce-address-fields{
                > p{
                    margin-bottom: 0;
                }
            }
            .edit-account, .woocommerce-address-fields__field-wrapper{
                display: flex;
                flex-wrap: wrap;
                margin-left: -15px;
                margin-right: -15px;
                fieldset{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    legend{
                        flex: 1 1 100%;
                        padding: 0 15px;
                    }
                    .form-row{
                        flex: 1 1 230px;
                        @include media-breakpoint-down(xs) {
                            flex: 1 1 100%;
                        }
                    }
                }
            }
        }
    }

    .woocommerce{

        @include media-breakpoint-down(sm) {
            padding: 1rem;
        }
        .u-columns{
            .u-column1, .u-column2{
                h2{
                    margin-bottom: 0;
                    padding-bottom: 1rem;
                    font-size: $font-size-h3;
                }
            }
        }
        .addresses{
            margin: 10px -15px;
            .woocommerce-Address, .col-1, .col-2{
                .woocommerce-Address-title, .title{
                    display: flex;
                    align-items: center;
                    padding-bottom: 10px;
                    h3{
                        flex: 1;
                        margin: 0;
                    }
                    .edit{
                        @include square(17px);
                        display: block;
                        flex: 0 0 17px;
                        line-height: 17px;
                        font-size: 0;
                        &:after{
                            font-family: FontAwesome;
                            content: '\f040';
                            font-size: 15px;
                            color: $brand-primary;
                        }
                    }
                }
                @include media-breakpoint-down(sm) {
                    width: 50%;
                    float: left;
                }
                @include media-breakpoint-down(xs) {
                    width: 100%;
                    float: none;
                }
            }
        }
        .woocomerce-form, .register, .login{
            margin: 0;
            flex: 1 1 100%;
            padding: 0;
            background-color: transparent;
            border: 0;
            transition: all 0.3s ease-in;

            @include hover-state {
                padding: 20px;
                box-shadow: 0 0 1px $gray-light;
                background-color: $gray-lightest;
            }
            p.form-row{
                padding: 0;
                margin: 0 0 1rem 0;

                &:last-child{
                    display: flex;
                }
                label{
                    line-height: 1;
                }
                .woocommerce-Input{
                    padding: 0.5rem;
                    border-color: $gray-light;
                }
                .woocommerce-Button{
                    width: 200px;
                    margin: 0 0 1rem 0;
                }
                .woocommerce-form__label-for-checkbox{
                    .woocommerce-form__input-checkbox{
                        vertical-align: middle;
                        margin-right: 7px;
                    }
                    span{
                        vertical-align: middle;
                    }
                }
            }
            .woocommerce-LostPassword{
                margin-bottom: 0;
            }
        }
    }
}