// Title
.title{
    padding-bottom: 15px;
    position: relative;
    z-index: 2;
}

// Intro slider
.intro-slider{
    position: relative;
    .slick-arrow{
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
    .slick-dots{
        @include position(absolute, auto, 0, -45px, 0);
        text-align: center;
        @include media-breakpoint-down(sm) {
            @include position(absolute, auto, 0, -15px, 0);
        }
    }
    .carousel-item{
        .carousel-item-inner{
            position: relative;
            .slider-image-col{
                margin-top: 20px;
                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
                .slider-image-col-image{
                    @include square(100%);
                    @include responsive-ratio(7, 5, true);
                    background-size: cover;
                    background-position: center;
                    position: relative;
                    z-index: 1;
                    @include media-breakpoint-down(md) {
                        @include responsive-ratio(5, 7, true);
                    }
                    @include media-breakpoint-down(sm) {
                        @include responsive-ratio(7, 5, true);
                        width: 100%;
                    }
                }
            }
            .slider-text-col{
                position: relative;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                padding-left: 70px;
                padding-right: 50px;
                min-height: 550px;
                @include media-breakpoint-down(sm) {
                    padding: 0;
                    margin-left: 0;
                    order: 1;
                }
                &:after{
                    content: "";
                    @include position(absolute, 0, auto, 0, 0);
                    @include size(150%, 100%);
                }
                .slider-text-box{
                    max-width: 450px;
                    position: relative;
                    z-index: 5;
                    @include media-breakpoint-down(sm) {
                        margin-top: 0;
                        padding: 1.5rem;
                        text-align: center;
                        max-width: 100%;
                    }
                    p{
                        color: #525454;
                        margin-bottom: 0;
                    }
                    .btn{
                        margin: 10px 10px 0 0;
                        @include media-breakpoint-down(xs) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

// Shop module
.shop-module{
    .shop-header{
        display: flex;
        margin-bottom: 1.5rem; 
        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
        }
        .shop-header-tabs{
            flex: 1;
            display: flex;
            @include media-breakpoint-down(xs) {
                justify-content: center;
            }
        }
        .shop-header-button{
            flex: 0 0 125px;
            text-align: right;
            @include media-breakpoint-down(xs) {
                display: none;
            }
        }
    }
    .shop-footer{
        padding: 1rem 0;
    }
}

// Collections
.collections{
    margin: {
        top: 15px;
    }
    .collection{
        margin-bottom: 30px;
        transition: all 0.25s ease-in;

        @include media-breakpoint-down(xs) {
            &:last-of-type{
                margin-bottom: 0;
            }
        }

        .collection-block{
            transition: all 0.25s ease-in;
            display: flex;
            flex-direction: column;
            height: 100%;

            @include hover-state {
                box-shadow: 0 5px 5px rgba(0, 0, 0, 0.22);
                transform: scale(1.01);
                border-radius: $border-radius;

                .collection-body{
                    border-radius: 0 0 $border-radius $border-radius;
                }
                .collection-images .collection-image, > .collection-images > .image-placeholder,
                .brand-image-wrapper{
                    border-radius: $border-radius $border-radius 0 0;
                }
            }
        }
        .collection-images{
            a{
                display: block;
            }
            > .image-placeholder{
                @include responsive-ratio(1, 1, true);
                background-color: $brand-primary;
                .fa{
                    font-size: 30px;
                }

                &.brand-placeholder{
                    @include responsive-ratio(3, 1, true);
                    background-color: #ffffff;
                }
            }
            .collection-image, > .image-placeholder{
                transition: all 0.5s ease-in;
                transform-origin: center center;
                display: block;
                position: relative;
                z-index: 1;
            }
            .brand-image-wrapper{
                display: flex;
                padding: 0;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;
                transition: all 0.5s ease-in;
                transform-origin: center center;

                @include responsive-ratio(3, 1, true);
                .brand-image{
                    max-height: 50px;
                    height: auto;
                    display: block;
                    position: absolute;
                }
            }
            .collection-gallery{
                display: flex;
                &.empty{
                    @include responsive-ratio(4, 1, true);
                    &:before{
                        flex: 0;
                    }
                }
                .collection-gallery-img{
                    flex: 0 1 92.5px;
                    @include media-breakpoint-down(xs) {
                        flex: 0 1 80px;
                    }
                    .image-placeholder{
                        @include responsive-ratio(1, 1, true);
                        .fa{
                            font-size: 18px;
                        }
                    }
                    &.more-images{
                        flex: 1 0 25%;
                        background-color: $gray-lighter;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: $weight-bold;
                        color: $gray;
                    }
                }
            }
        }
        .collection-body{
            padding: 2rem 1.5rem;
            text-align: center;
            display: block;
            text-decoration: none;
            background-color: lighten($gray-light, 5%);
            transition: all 0.25s ease-in;
            color: $gray;
            display: flex;
            flex-direction: column;
            flex: 1;

            .collection-title{
                @include text-ellipsis(2, 48px);
                color: #ffffff;
                margin-bottom: 1rem;
            }
            .collection-paragraph{
                @include text-ellipsis(2, 50px);
                color: #ffffff;
            }
            .btn{
                max-width: 230px;
                color: currentColor;
                margin: auto auto 0 auto;
                @include hover-state {
                    background-color: $gray-lighter;
                    color: currentColor !important;
                }
            }
        }
    }
}


// Brands
.brands{
    .brand{
        margin-bottom: 1rem;
    }
}

.logo-slider{
    padding: 1rem 0 2rem 0;
    .logo-carousel{
        position: relative;
        margin-left: -15px;
        margin-right: -15px;

        .carousel-item{
            margin: 10px;
            outline: 0;

            img{
                max-width: 150px;
                height: auto;
                display: block;
                margin: 0 auto;
            }
        }

        > .slick-arrow{
            display: none !important;
        }
    }
    .arrows{
        @include size(80px, 40px);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        border-radius: 4px;
        margin: 25px auto 0 auto;
        padding: 5px 0;
        display: block;

        .slick-arrow{
            @include size(40px, 30px);
            transform: translate(0,0);
            text-align: center;
            line-height: 30px;
            display: inline-block;
            padding: 0;
            cursor: pointer;
            border: none;
            outline: none;
            color: #8b8b8c;
            position: static;
            top: auto;
            border-right: 2px solid $gray-lighter;
            &:before{
                font-size: 23px;
            }
            &:last-of-type{
                border-right: 0;
            }
        }
    }
}

// Wysiwyg
.wysiwyg{
    padding: 25px 0 10px 0;

    h1, .h1, p{
        margin-bottom: 1.5rem;
    }
    p:last-of-type{
        margin-bottom: 0;
    }
}

// Image Text
.image-text{
    padding: 2rem 0;
    margin-left: -40px;
    margin-right: -40px;
    @include media-breakpoint-down(md) {
        margin-left: -20px;
        margin-right: -20px;
    }
    .image-text-img-col, .image-text-cnt-col{
        padding: 0 40px;
        @include media-breakpoint-down(md) {
            padding: 0 20px;
        }
    }
    .image-text-img-col{
        .image-text-img{
            @include responsive-ratio(4, 3, true);
            @include center-cover();
            @include media-breakpoint-between(sm, md) {
                @include responsive-ratio(1, 1, true);
            }
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 1.5rem;
        }
        &.right{
            order: 1;
            @include media-breakpoint-down(sm) {
                order: 0;
            }
        }
    }
    .image-text-cnt-col{
        display: flex;
        align-items: center;
        &.left{
            order: 0;
            @include media-breakpoint-down(sm) {
                order: 1;
            }
        }
    }

}