.mobile-header{
    position: relative;
    display: none;
    text-align: left;
    background-color: $brand-primary;
    left: 0;
    right: 0;

    @include media-breakpoint-up(md) {
        display: none;
    }
    @include media-breakpoint-down(sm) {
        display: block;
    }
    &.scroll{
        box-shadow: 0 4px 3px -3px rgba(#000000, 0.10);
    }
    &.fixed{
        position: fixed;
        backface-visibility: hidden;
        transition: transform 300ms ease;
        z-index: 200;
    }
    &.fixed-menu-open{
        transform: translate3d(-260px, 0px, 0px);
    }
    &.fixed-cart-open{
        transform: translate3d(260px, 0px, 0px);
    }

    .mobile-header-content{
        padding: 10px 15px;
        display: flex;
        align-items: center;
    }
    .logo{
        flex: 0 0 90px;
        @include size(90px, 25px);
        @include position(12.5px, auto, auto, 50%);
        background-image: url(#{$images-path}/logo.png?v=3.0);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
    }
    .toolbar{
        flex: 1 1 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .toolbar-item{
            border-right: 1px solid $gray-lighter;
            padding: 0 7px;
        }

        .navbar-toggler{
            margin: 0;
            padding: 10px 0 10px 7px;
            flex: 0 0 18px;
            border: 0;
            cursor: pointer;

            .icon-bar{
                background-color: $brand-secondary;
                @include size(18px, 1.5px);
                display: block;
                transition: all 0.2s;
                margin: 0 auto;
                border-radius: 1px;

                &+ .icon-bar{
                    margin-top: 5px;
                }
            }
            .top-bar{
                transform: rotate(0);
            }
            .middle-bar{
                opacity: 1;
            }
            .bottom-bar{
                transform: rotate(0);
            }
            &.active {
                .icon-bar {
                    &.top-bar {
                        transform: translate(0px, 6.5px) rotate(45deg);
                    }
                    &.middle-bar {
                        transform: translateX(100px);
                        opacity: 0;
                    }
                    &.bottom-bar {
                        transform: translate(0px, -6.5px) rotate(-45deg);
                    }
                }
            }
        }
        .cart-dropdown{
            flex: 0 0 34px;
        }
        .heart-icon{
            flex: 0 0 34px;
        }
        .account-icon{
            flex: 0 0 34px;
        }
        .badge{
            @include position(absolute, -2px, 4px, auto, auto);
            font-size: 0.7rem;
            font-weight: $weight-regular;
            transition: transform 0.6s cubic-bezier(1,0.2,0.12,3), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            transform: scale(1);
            &.empty{
                @include opacity(0);
                transform: scale(0);
            }
        }
        > .btn, > .dropdown > .btn{
            @include size(auto, auto);
            line-height: 30px;
            text-align: center;
            padding: 0 0.5rem;
            font-size: 1rem;
            position: relative;
            @include hover-state {
                box-shadow: none;
            }
            .icon{
                vertical-align: middle;
            }
            &.no-padding{
                padding: 0;
            }
        }
        .heart{
            .badge{
                @include position(absolute, -2px, -2px, auto, auto);
            }
        }
        .dropdown{
            display: block;
            height: auto;
            @include media-breakpoint-down(xs) {
                position: static;
            }
            &.icon-dropdown{
                .dropdown-toggle{
                    @include size(auto, auto);
                    border: 0;
                    padding: 0;
                    &:after{
                        margin-left: 0;
                    }
                }
                .dropdown-menu{
                    @include calc(top, "100% + 10px", 100%);
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
                    border: 0;
                    padding: 20px;
                    margin-top: 0;
                    
                    @include media-breakpoint-down(xs) {
                        border-radius: 0;
                        min-width: 0;
                        top: 100%;
                        left: 0;
                        right: 0;
                    }

                    &.dropdown-menu-search{
                        padding: 10px;
                    }
                }
            }
            .dropdown-menu-cart{
                min-width: 300px;
                max-width: 1000px;
                max-height: 400px;
                overflow: auto;
            }
        }
        .search{
            flex: 0 0 30px;
            &.show{
                > .btn > .icon-search{
                    &::before{
                        width: 0px;
                        border-width: 1px 1px 0 0;
                        border-radius: 0;
                        transform: translate(0) rotate(45deg);
                    }
                    &::after{
                        width: 15px;
                        transform: translate(0) rotate(45deg)
                    }
                }
            }
            .search-form{
                .search-submit{
                    @include square(14px);
                    @include position(absolute, 8px, auto, auto, 12px);
                }
            }
        }
    }
}