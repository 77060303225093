.woocommerce-cart, .woocommerce-account, .woocommerce-checkout{
    .main-content .container{
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 15px;

        @include media-breakpoint-down(xs) {
            min-height: 0;
        }
    }
    .main{
        max-width: 100%;
        margin: auto;
        padding: 0;
        box-shadow: 0 0 3px #e5e5e5;
        border-radius: $border-radius;
        background-color: #ffffff;

        &.empty-cart{
            box-shadow: none;
            border-radius: 0;
            background-color: transparent;
            .woocommerce{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .woocommerce-message{
                    margin-bottom: 1rem;
                    width: auto;
                    display: inline-block;
                }
                .error{
                    margin-top: 1rem;
                }
            }
        }
    }
}

.col2-set{
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    width: auto !important;

    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }
    .col-1, .col-2{
        flex: 1 1 50%;
        max-width: 100%;
        @include media-breakpoint-down(sm) {
            flex: 1 1 100%;
            margin-bottom: 1rem;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
}

.woocommerce input.button, .woocommerce .woocommerce-Button{
    font-size: 0.9rem;
    text-transform: uppercase;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: all 0.3s ease-in;
    padding: .8rem 1.8rem;
    float: right;
    line-height: 1;
    border-radius: $border-radius;
    font-weight: $weight-bold;
    text-decoration: none;
    background-color: $brand-primary;
    color: #ffffff;
    @include hover-state {
        background-color: darken($brand-primary, 10%);
        color: #ffffff;
    }
}

.woocommerce{
    form{
        h3{
            margin-bottom: 1rem;
        }
        > p{
            &:last-child{
                margin-bottom: 0;
            }
        }
        .form-row{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex: 1 1 230px;
            width: auto;
            padding: 0 15px;
            float: none;
            margin: 0 0 1rem 0;
            
            label{
                line-height: 1;
            }
            .input-text{
                padding: 0.5rem;
                border-color: $gray-light;
            }
            input.input-text {
                flex: 0 0 34px;
            }
        }
        .woocommerce-Button{
            margin-left: 15px;
        }
    }

    .table{
        thead{
            tr{
                th{
                    border-bottom: 0;
                    border-top: 0;
                }
            }
        }
        thead, tbody, tfoot{
            tr{
                th{
                    font-weight: $weight-bold;
                }
                th, td{
                    padding: .5rem 0;
                    color: #000000;
                    address{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
