.mini-cart{
    .mini-cart-spinner{
        height: 100px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before{
            @include square(40px);
            @include opacity(1);
            animation: rotating 1.5s linear infinite;
            font-family: 'FontAwesome';
            content: "\f110";
            line-height: 40px;
            text-align: center;
            font-size: 2rem;
            color: $brand-primary;
            transition: all 0.3s ease-in;
        }
    }

    // Product listing
    .cart_list{
        // Media object
        .media{
            align-items: stretch;
            margin-bottom: 10px;
            &:last-of-type{
                margin-bottom: 0;
            }
            .media-left{
                a{
                    display: block;
                    img{
                        @include square(60px);
                    }
                }
            }
            .media-body{
                position: relative;
                padding: 0 20px 0 10px;
                .media-heading{
                    margin-bottom: 0;
                }
                .remove{
                    @include size(20px, 20px);
                    @include position(absolute, 50%, 0, auto, auto);
                    transform: translate(0,-50%);
                    font-size: 25px;
                    line-height: 22px;
                    font-weight: $weight-light;
                    color: #000000 !important;
                    cursor: pointer;
                    @include hover-state {
                        background-color: #000000;
                        color: #ffffff !important;
                    }
                }
            }
        }
    }

    // Buttons
    .buttons{
        margin-bottom: 0;
        .button{
            @extend .btn;
            font-size: 0.7rem;
            padding: 0.55rem 1.2rem;
            margin-right: 10px;
            &:first-of-type{
                @extend .btn-secondary;
            }
            &.checkout{
                @extend .btn-primary;
            }
        }
    }

    // Empty
    .woocommerce-mini-cart__empty-message{
        margin-bottom: 0;
    }

    // Total
    .total{
        margin: 10px 0;
    }
}