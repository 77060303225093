.block{
    padding: 3rem 0;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #ffffff;

    @include media-breakpoint-down(sm) {
        padding: 2rem 0;
    }
    &.padding-zero{
        padding: 0 !important;
    }
    &.shop-section{
        padding: 20px 0 2.5rem;
        @include media-breakpoint-down(xs) {
            .container{
                padding: 0;
            }
        }
    }
    &.big-block{
        padding: 6rem 0;
        @include media-breakpoint-down(sm) {
            padding: 3rem 0;
        }
    }
    &.wider-block{
        .container{
            max-width: $max-width-larger;
        }
    }
    &.cover{
        background-size: cover;
    }
    &.dark{
        h1, h2, h3, h4, h5, h6{
            color: #ffffff;
        }
        color: #cfcfcf;
    }
}