header{
    @include size(100%, 30px);
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &.fixed{
        position: fixed;
        backface-visibility: hidden;
        transition: transform 300ms ease;
        z-index: 200;
    }
    &.fixed-menu-open{
        transform: translate3d(260px, 0px, 0px);
    }
    &.fixed-cart-open{
        transform: translate3d(-260px, 0px, 0px);
    }
    &.auto-hide-header{
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        will-change: transform;
        -webkit-transition: -webkit-transform .5s;
        transition: -webkit-transform .5s;
        transition: transform .5s;
        transition: transform .5s, -webkit-transform .5s;
        &::after{
            clear: both;
            content: "";
            display: block;
        }
        &.is-hidden {
            -webkit-transform: translateY(-100%);
                -ms-transform: translateY(-100%);
                    transform: translateY(-100%);
        }
    }
}
.top-header{
    @include size(100%, auto);
    background-color: $gray-lighter;
    min-height: 30px;
    line-height: 30px;
    color: $gray;
    font-size: .8rem;
    position: relative;

    p{
        margin-bottom: 0;
        a{
            color: $gray;
        }
    }
    .top-header-menu{
        margin: 0;
        padding: 0;
        list-style: none;

        li{
            display: inline-block;
            padding-right: 10px;
            a{
                color: $gray;
            }
        }
    }
}
.main-header{
    @include size(100%, auto);
    background-color: $brand-primary;
    padding: 20px 0;
    position: relative;

    &.scroll{
        box-shadow: 0 4px 3px -3px rgba(#000000, 0.10);
    }

    .container{
        display: flex;
    }

    .logo{
        flex: 0 0 180px;
        background-image: url(#{$images-path}/logo.png?v=3.0);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        align-items: stretch;
    }

    .navbar{
        padding: 0 0 0 20px;
        flex: 1 1;
        @include media-breakpoint-down(sm) {
            display: none;
        }
        .navbar-nav{
            flex-direction: row;

            li{
                margin-left: 0;
                display: inline-block;
                float: none;
                .nav-link{
                    font-weight: $weight-bold;
                    text-transform: uppercase;
                    position: relative;
                    font-size: 0.8rem;
                    padding: .65em 1.2em;
                }
                &:first-child{
                    .nav-link{
                        padding-left: 0;
                    }
                }
                &.dropdown{
                    @include hover-state {
                        .dropdown-menu{
                            display: block;
                        }
                    }
                }
                .dropdown-menu{
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
                    border: 0;
                    border-radius: 1rem;
                    padding: 0;
                    margin: 0;
                    li{
                        display: block;
                        &.item-active{
                            .nav-link{
                                background-color: $brand-secondary;
                                color: #ffffff;
                            }
                        }
                        &:first-child a{
                            border-radius: 1rem 1rem 0 0;
                        }
                        &:last-child a{
                            border-radius: 0 0 1rem 1rem;
                        }
                    }
                    .dropdown-item, .nav-link{
                        font-weight: $weight-bold;
                        text-transform: uppercase;
                        position: relative;
                        padding: .8em 1rem;
                        font-size: 0.7rem;
                        color: #000000;
                        &.item-active, &:hover, &:focus, &:active{
                            background-color: $brand-primary;
                            color: #ffffff;
                        }
                    }
                }
            }
            
            // Hover and active underscore
            >.menu-item:not(.dropdown) {
                &.item-active {
                    >.nav-link::after {
                        @include opacity(1);
                        transform: scaleX(1);
                    }
                }

                &:first-child {
                    >.nav-link {
                        &::after {
                        @include position(absolute, auto, 1.2em, 8px, 0);
                        }
                    }
                }
            }

            .item-active:not(.dropdown),
            .current-product-ancestor:not(.dropdown) {
                position: relative;

                &::after {
                @include position(absolute, auto, 1.2em, 8px, 1.2em);
                @include opacity(1);
                transform: scaleX(1);
                transform-origin: left;
                transition: transform 0.3s cubic-bezier(1, 1.1, 1, 0.9), opacity 0.3s cubic-bezier(1, 1.1, 1, 0.9);
                content: '';
                border-bottom: 2px solid;

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                @include hover-state {
                    &::after {
                        @include opacity(1);
                        transform: scaleX(1);
                    }
                }
            }

        }
    }
    .search{
        flex: 0 0 180px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        .search-form{
            .search-field{
                text-indent: 25px;
                transition: all 0.3s ease-in;
                &.active{
                    width: 250px;
                }
            }
            .search-submit{
                @include position(absolute, 8px, auto, auto, 12px);
            }
        }   
    }
    .toolbar{
        flex: 0 0 105px;
        display: flex;
        align-items: center;
        .dropdown{
            display: inline-block;
            position: relative;
            .btn{
                border-right: 0;
                &.dropdown-toggle{
                    &:after{
                        height: 10px;
                        margin-left: .2em;
                    }
                }
            }
            
            .dropdown-menu{
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
                border: 0;
                border-radius: 1rem;
                max-width: 200px;
                padding: 25px;
                margin: 0;
                color: #000000;
                top: 150%;

                &.dropdown-menu-cart{
                    min-width: 330px;
                    max-width: 1000px;
                    max-height: 400px;
                    overflow: auto;
                }
                
                ul{
                    list-style: none;
                    padding-left: 0;
                    margin-bottom: 0;
                    li{
                        a{
                            text-decoration: none;
                        }
                    }
                }
            }
            &.right{
                .dropdown-menu{
                    right: 0;
                    left: auto;
                }
            }
        }
        .icon{
            @include square(1.15em);
        }
        .badge{
            @include position(absolute, 3px, 20px, auto, auto);
            font-size: 0.7rem;
            font-weight: $weight-regular;
            transition: transform 0.6s cubic-bezier(1,0.2,0.12,3), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            transform: scale(1);
            &.empty{
                @include opacity(0);
                transform: scale(0);
            }
        }
        > .btn, .dropdown > .btn{
            @include size(auto, 40px);
            line-height: 45px;
            text-align: center;
            font-size: 1.15rem;
            border-right: 1.5px solid $brand-secondary;
            padding: 0 0.5rem;
            outline: 0;
            position: relative;

            @include hover-state {
                box-shadow: none;
            }
        }
        .heart{
            .badge{
                @include position(absolute, 3px, 0, auto, auto);
            }
        }
        .dropdown > .dropdown-cart{
            border-right: 0;
        }
    }
}

.top-header, .main-header{
    .container{
        max-width: $max-width-larger;
    }
}