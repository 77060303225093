.comments{
    padding: 0;

    h2{
        font-size: 17px;
        font-weight: $weight-bold;
    }

    ol.comment-list, ol {
        padding: 0;
        margin: 20px 0 25px 0;
        @include list-unstyled;

        li{
            padding: 15px;
            border: 2px solid darken($gray-lighter, 10%);
            
            .comment-body{
                .comment-meta{
                    margin-bottom: 5px;
                    .comment-author{
                        margin-bottom: 10px;

                        .avatar{
                            @include border-radius(50%);
                            margin-right: 5px;
                        }
                    }
                }
                .comment-content p{
                    color: $brand-primary;
                }
            }
            .children{
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
    #reply-title{
        margin: 5px 0;
    }
    .comment-form{
        input[type="text"], input[type="email"], input[type="url"], textarea{
            @include size(100%, auto);
            appearance: none;
            max-width: 100%;
            outline: 0;
            border: 2px solid darken($gray-lighter, 10%);
            padding: 10px;

            &:focus{
                @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6));
                border-color: $brand-primary;
                outline: 0;
            }
        }
        .form-submit{
            margin: 0;
            input[type="submit"]{
                @extend .btn;
                @extend .btn-primary;
            }
        }
    }

}