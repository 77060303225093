:root, html{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
}
body{
    @include size(100%, 100%);
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    &.open{
        overflow: hidden;
    }
}
*{
    box-sizing: border-box;
    @include hover-state {
        outline: 0;
    }
}