.main{
    .page-description{
        display: none;
    }
    .woof_products_top_panel{
        z-index: 1;
        @include media-breakpoint-down(sm) {
            margin: 0;
            padding: 0.5rem 0 !important;
        }
        ul{
            @include media-breakpoint-down(xs) {
                text-align: center;
            }
            li{
                margin-right: 10px;
                float: none;
                display: inline-block;
                a{
                    padding: 5px 10px;
                    background-color: $brand-primary;
                    color: #ffffff;
                    border-radius: 15px;
                    transition: all 0.25s ease-in;
                    @include hover-state {
                        background-color: darken($brand-primary, 5%);
                    }
                    span{
                        background: transparent;
                        position: relative;
                        padding-right: 15px;
                        &::before, &::after{
                            @include position(absolute, 50%, 0, auto, auto);
                            @include size(12px, 2px);
                            background-color: currentColor;
                            margin-top: -1px;
                            content: '';
                        }
                        &::before{
                            transform: rotate(45deg);
                        }
                        &::after{
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }
}

.woocommerce-header-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
}

a[data-tax="orderby"]{
    display: none !important;
}
        
#woof_results_by_ajax, 
.woof_shortcode_output{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1 1 100%;
    width: 100%;

    @include media-breakpoint-down(xs) {
        text-align: center;
    }
    .woof_products_top_panel{
        flex: 1 1 100%;
    }
    .woocommerce-result-count, 
    .woocommerce-ordering{
        float: none;
    }
    .woocommerce-result-count{
        flex: 1 1 200px;
        margin-bottom: 0;
        @include media-breakpoint-down(xs) {
            padding: 0.5rem 0;
            flex: 1 1 100%;
        }
    }
    .woocommerce-ordering{
        flex: 0 1 200px;
        display: flex;
        align-items: flex-end;
        margin-bottom: 0;
        z-index: 1;
        @include media-breakpoint-down(xs) {
            flex: 1 1 100%;
            .select-wrapper{
                margin: 0 auto;
            }
        }
    }
    .products{
        flex: 1 1 100%;
    }
}