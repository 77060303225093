.image-placeholder{
    background-color: $gray-light;
    position: relative;
    display: block;
    .image-placeholder-wrapper{
        @include size(100%, auto);
        @include position(absolute, 50%, 0, auto, 0);
        transform: translate(0, -50%);
        font-size: 13px;
        text-align: center;
        color: $gray-lighter;
        .image-placeholder-icon{
            margin: 0 auto 10px auto;
            .fa{
                font-size: 40px;
                line-height: 40px;
                text-align: center;
                color: $gray-lighter;
            }
        }
        .image-placeholder-text{
            padding-top: 0.3rem;
        }
    }
    &.dark{
        background-color: $gray-dark;
        .image-placeholder-wrapper{
            color: #ffffff;
            .image-placeholder-icon .fa{
                color: #ffffff;
            }
        }
    }
}

.inverse{
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.browserhappy{
    @include size(100%, auto);
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #f21813;
    color: #ffffff;
    border: 0;
    text-align: center;
    padding: 2rem 1rem;
    margin-bottom: 0;
    border-radius: 0;
    z-index: 110;
    a{
        text-transform: uppercase;
        text-decoration: underline;
        color: #ffffff;
    }
}