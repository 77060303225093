#yith-wcwl-form{
    @include media-breakpoint-down(sm) {
        padding: 1rem 0;
    }

    .wishlist-title h2{
        padding: 0;
    }

    &.empty-wishlist{
        min-height: 550px;
        display: flex;
        justify-content: center;
        align-items: center;
        .wishlist-title{
            display: none;
        }
    }
}