.woocommerce-page {
    &.single-product{
        div.product {
            background-color: #fafafa;
            
            @include media-breakpoint-down(sm) {
                background-color: #ffffff;
                .single-product-row{
                    margin-right: 0;
                    margin-left: 0;
                }
            }
            
            // Images
            .single-image-box{
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
                &.empty{
                    .images{
                        @include responsive-ratio(7, 6, true);
                        background-color: $brand-primary;
                        position: relative;
                        @include media-breakpoint-only(md) {
                            @include responsive-ratio(5, 7, true);
                        }
                        &:after{
                            @include square(40px);
                            @include position(absolute, 50%, auto, auto, 50%);
                            font-family: 'FontAwesome';
                            content: "\f03e";
                            transform: translate(-50%, -50%);
                            font-size: 40px;
                            line-height: 40px;
                            text-align: center;
                            color: #ffffff;
                        }
                    }
                }
                .images{
                    width: 100%;
                    margin-bottom: 0;
                    .woocommerce-product-gallery__image--placeholder{
                        margin: 0;
                    }
                    .MagicToolboxContainer{
                        width: 100%;
                        float: none;
                        margin-bottom: 0;
                        display: flex;
                        align-items: stretch;
                        @include media-breakpoint-down(md) {
                            flex-wrap: wrap;
                        }

                        .magic-slide{
                            display: flex;
                            order: 2;
                            margin-bottom: 0;
                            
                            @include media-breakpoint-down(md) {
                                order: 0;
                            }
                            &.mt-active{
                                flex: 1 1 100%;
                            }
                            .MagicZoom{
                                margin: 0;
                                width: 100%;
                            }

                            figure{
                                @include size(100%, auto);
                                margin-bottom: 0;
                                img{
                                    max-width: 100% !important;
                                    max-height: 100% !important;
                                }
                                a{
                                    display: block;
                                }
                            }
                        }
                        .MagicToolboxSelectorsContainer{
                            display: flex;
                            flex: 0 0 120px;
                            padding: 10px;

                            @include media-breakpoint-down(md) {
                                flex: 1 0 100%;
                            }

                            >div{
                                display: flex;
                                flex: 1 0 100%;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;

                                @include media-breakpoint-down(md) {
                                    flex: 1 0 100%;
                                    flex-direction: row;
                                }
                            }

                            .lightbox-added{
                                width: 100%;
                                float: none;
                                margin: 0 0 10px 0;
                                display: block !important;

                                @include media-breakpoint-down(md) {
                                    flex: 0 1 93px;
                                    margin-bottom: 0;
                                    margin-right: 10px;
                                    width: auto;
                                    &:last-of-type{
                                        margin-right: 0;
                                    }
                                }
                                @include media-breakpoint-down(xs) {
                                    flex: 0 1 80px;
                                }
                            }
                        }
                    }
                }
            }

            // Content
            .single-product-info{
                display: flex;
                align-items: center;
                justify-content: center;

                @include media-breakpoint-down(sm) {
                    padding-top: 20px;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                .onsale{
                    @include position(absolute, 10px, auto, auto, -85px);
                    border-radius: 20px;
                    background-color: #ffffff;
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
                    padding: 0.6rem 1.1rem;
                    line-height: normal;
                    min-height: 0;
                    color: red;
                }

                .single-product-info-holder{
                    max-width: 320px;
                }

                // Title
                .product_title{
                    font-size: 2rem;
                    margin-bottom: 0;
                }

                // Price
                .price{
                    font-family: "Open Sans Condensed";
                    color: #000000;
                    text-transform: none;
                    padding: 0;
                    font-weight: $weight-bold;
                    line-height: normal;
                    margin: 0;
                    .amount{
                        font-size: 1.7rem;
                    }
                    .woocommerce-Price-currencySymbol{
                        font-size: 0.9rem;
                    }
                }

                // Meta
                .product_meta{
                    padding: 15px 0;
                    color: #505050;
                }

                // Cart form
                .cart{
                    margin: 0;
                    .woocommerce-qty{
                        display: flex;
                        align-items: center;
                        padding: 10px 0;
                        .quantity-label{
                            flex: 0 0 40px;
                            color: $gray-dark;
                        }
                        .quantity{
                            flex: 0 0 80px;
                            .qty{
                                border-radius: 1.5rem;
                            }
                        }
                        .quantity{
                            flex: 0 0 50px;
                            float: none;
                            input.qty{
                                @include size(60px, 40px);
                                appearance: none;
                                border-radius: 20px;
                                border: 1px solid $gray-lighter;
                                font-weight: $weight-bold;
                                outline: 0;
                                text-align: center;
                                padding: 0 10px;
                                max-width: 100%;
                                margin: 0;

                                @include placeholder() {
                                    color: #000000;
                                }
                            }
                        }
                        .calculate-qty{
                            flex: 1;
                            text-align: right;
                        }
                    }
                    // Buttons
                    .woocommerce-buttons {
                        padding: 10px 0;
                        .btn{
                            width: 100%;
                            @include media-breakpoint-down(xs) {
                                font-size: 0.8rem
                            }
                            &.order-wallpaper{
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }

        }
    }
}