@mixin hover-state {
    &:hover, &:active, &:focus {
        @content;
    }
}

@mixin square($size) {
    width: $size;
    height: $size;
}

@mixin block-center {
    display: block;
    margin: { 
        left: auto;
        right: auto;
    }
}

@mixin opacity($value){
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

@mixin position($postion: relative, $top: auto, $right: auto, $bottom: auto, $left: auto) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: $postion;
}

@mixin opacity($opacity: 1) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin columns($columns, $gap: 30px) {
    columns: $columns;
    -webkit-columns: $columns;
    -moz-columns: $columns;
    -webkit-column-gap: $gap;
    -moz-column-gap: $gap;
    column-gap: $gap;
}

@mixin calc($property, $expression, $fallback) { 
    #{$property}: #{$fallback};
    #{$property}: -webkit-calc(#{$expression}); 
    #{$property}: calc(#{$expression}); 
} 

@mixin center-cover(){
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin background-size($size){
    background-size: $size;
    -webkit-background-size: $size;
    -moz-background-size: $size;
}

@mixin border($width, $style, $color){
    border-width: $width;
    border-style: $style;
    border-color: $color;
}

@mixin text-ellipsis($lines, $maxheight) {
    @if $lines == 1 {
        max-height: ($maxheight + 2);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-top: 1px;
    } @else {
        max-height: ($maxheight + 2);
        max-width: 100%;
        white-space: normal;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: $lines;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-top: 1px;
        padding-bottom: 2px;
        text-overflow: ellipsis;
    }
}

@mixin ellipsis-clear() {
    height: auto;
    display: block;
    -webkit-line-clamp: none;
    white-space: normal;
    overflow: visible;
    text-overflow: visible;
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

@mixin clear-responsive-ratio() {
    &:before {
        display: none;
    }
    padding-top: initial;
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes $animation-name {
        @content;
    }
    @-moz-keyframes $animation-name {
        @content;
    }
    @keyframes $animation-name {
        @content;
    }
}

@mixin placeholder(){
    &::-webkit-input-placeholder{
        @content;
    }
    &:-moz-placeholder{
        @content;
    }
    &::-moz-placeholder{
        @content;
    }
    &:-ms-input-placeholder{
        @content;
    }
}

@mixin scrollbar($width, $scrollbar-bg, $thumb-bg){
    &::-webkit-scrollbar {
        width: $width;
        height: $width;
        background-color: $scrollbar-bg;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-corner {
        background-color: darken($scrollbar-bg, 5%);
    }
    &::-webkit-scrollbar-thumb {
        background-color: $thumb-bg;
        border-radius: 4px;
    }
}

@mixin backdrop-blur($color, $fallback-color, $opacity: 0.7, $fallback: 1, $fixed: false){
    @include square(100%);
    @if $fixed {
        @include position(fixed, 0, auto, auto, 0);
    } @else {
        @include position(absolute, 0, auto, auto, 0);
    }
    background-color: rgba($fallback-color, $fallback);
    z-index: -1;
    content: ' ';

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        -webkit-backdrop-filter: saturate(250%) blur(10px);
        backdrop-filter: saturate(250%) blur(10px);
        background-color: rgba($color, $opacity);
    }
}

@mixin retina($image) {
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (   min--moz-device-pixel-ratio: 2),
    only screen and (     -o-min-device-pixel-ratio: 2/1),
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) { 
        background-image: url($image);
    }
}