.error-back{
    .container{
        background-image: url(#{$images-path}/slider-bg.jpg);
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70vh;
        max-width: $max-width-larger - 30px;
        min-height: 370px;
    }
}

.error{
    text-align: center;
    .error-icon{
        position: relative;
        display: inline-block;
        .icon{
            font-size: 5rem;
            margin-bottom: 1rem;
            position: relative;
        }
        .badge{
            @include position(absolute, -5px, -5px, auto, auto);
            font-weight: $weight-regular;
            font-size: 20px;
        }
    }
    .error-title{
        font-size: 12rem;
        @include media-breakpoint-down(sm) {
            font-size: 9rem;
        }
    }
    .error-text{
        font-size: 1.1rem;
    }
}