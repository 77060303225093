// Select boxes
:root select{background: transparent\d;}
select{
    @include size(auto, 40px);
    background-color: #ffffff;
    font-weight: $weight-regular;
    font-size: 0.9rem;
    cursor: pointer;
    outline: 0;
    padding: 0 20px 0 15px;
    appearance: none;
    -webkit-user-select: none;
    border-radius: 20px;
    box-shadow: 0;
    white-space: nowrap;
    border: 1px solid;
}

.select-wrapper{
    display: inline-block;
    position: relative;
    &:after{
        @include square(20px);
        @include position(absolute, 10px, 10px, auto, auto);
        background-color: #ffffff;
        font-family: "FontAwesome";
        display: block;
        vertical-align: middle;
        content: "";
        content: "\f078";
        color: #000000;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        @include media-breakpoint-down(xs) {
            background-color: transparent;
        }
    }
    @-moz-document url-prefix() {
        background-color: #ffffff;
        padding: 0;
    }
}

@include media-breakpoint-down(xs) {
    select{
        border: 0;
        background-color: transparent;
        padding: 0;
    }
}

// Checkbox buttons
input[type="checkbox"]{
    @include square(17px);
    -webkit-appearance: button;
    border: 2px solid $brand-primary;
    background: transparent;
    position: relative;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
    text-align: center;
    color: $brand-primary;
    font-size: 12px;
    line-height: 17px;
    &.active{
        background-color: $brand-primary;
        position: relative;
        &:after{
            @include position(absolute, 40%, auto, auto, 50%);
            transform: translate(-50%, -40%) rotate(45deg);
            content: " ";
            display: block;
            width: 0.4em;
            height: 0.6em;
            border: solid #fff;
            border-width: 0 0.2em 0.2em 0;
        }
    }
}

// Radio buttons
input[type="radio"]{
    @include square(15px);
    border-radius: 50%;
    -webkit-appearance: button;
    border: 2px solid $brand-primary;
    background: transparent;
    position: relative;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
    text-align: center;
    color: $brand-primary;
    font-size: 12px;
    line-height: 17px;
    &.active{
        position: relative;
        &:after{
            content: "";
            @include square(7px);
            @include position(absolute, 50%, auto, auto, 50%);
            border-radius: 50%;
            background-color: $brand-primary;
            transform: translate(-50%, -50%);
            display: block;
        }
    }
}

// Inputs and textareas
input[type="text"],
input[type="email"],
input[type="date"],
input[type="password"],
input[type="tel"],
textarea{
    @include size(100%, auto);
    appearance: none;
    border: 1px solid;
    outline: 0;
    max-width: 100%;
    font-weight: $weight-regular;
    @include placeholder() {
        color: $gray;
    }
}
textarea{
    padding: 10px;
    @include size(100%, 120px);
    max-width: 100%;
    line-height: 1.2;
}

// Submit button
input[type="submit"]{
    width: auto;
    @extend .btn;
    @extend .btn-primary;
    margin-top: 7px;
    border: 0;
    display: block;
}