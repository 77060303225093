.woocommerce-checkout {
    .main-content .container{
        min-height: 0;
        flex-direction: column;
    }
    .main{
        > p{
            display: none;
        }
    }
    &.ikea{
        .main{
            background-color: transparent;
            box-shadow: none;
            border-radius: 0;
            text-align: center;

            input[type="submit"]{
                display: inline-block;
            }
        }
    }
    .woocommerce{
        flex-direction: column;
        padding: 2rem 2rem 0 2rem;
        width: 900px;
        max-width: 100%;

        @include media-breakpoint-down(xs) {
            padding: 1rem 1rem 0 1rem;
        }

        .woocomerce-form{
            margin: 0 0 1.5rem 0;
            .form-row{
                padding: 0;
            }
        }

        .checkout-header {
            width: auto;
            margin-top: -2rem;
            margin-left: -2rem;
            margin-right: -2rem;
            margin-bottom: 1rem;
            padding: 1rem 2rem;
            background-color: $brand-primary;
            border-radius: $border-radius $border-radius 0 0;

            @include media-breakpoint-down(xs) {
                margin-left: -1rem;
                margin-right: -1rem;
                padding: 1rem;
            }

            h3{
                color: #ffffff;
            }
        }
        form.woocommerce-checkout{
            width: 100%;
            padding-top: 1.5rem;
            .woocommerce-billing-fields__field-wrapper,
            .woocommerce-additional-fields,
            .woocommerce-account-fields,
            .shipping_address,
            .woocommerce-checkout-delivery-fields{
                margin-left: -15px;
                margin-right: -15px;
                .order_comments{
                    height: auto;
                    min-height: 100px;
                }
            }
            #ship-to-different-address {
                label {
                    margin-bottom: 0;
                }
            }
            div.blockUI.blockOverlay {
                width: auto !important;
                z-index: 10 !important;
                left: -2rem !important;
                right: -2rem !important;
                border-radius: 1.45rem !important;
            }
        }
        .woocommerce-checkout-review-order{
            .woocommerce-checkout-review-order-table{
                width: 100%;
                max-width: 100%;
                background-color: #ffffff;
                border: 0;
                margin: 10px 0;
                thead{
                    th{
                        padding: 5px 0;
                        background-color: #ffffff;
                        border-bottom: 0;
                        font-weight: $weight-bold;
                        color: #000000;
                    }
                }
                tbody, tfoot{
                    th, .amount{
                        font-weight: $weight-bold;
                        color: #000000;
                    }
                    th, td{
                        padding: 5px 0;
                        background-color: #ffffff;
                    }
                }
            }
            .woocommerce-checkout-delivery{
                width: auto;
                margin-left: -2rem;
                margin-right: -2rem;
                background-color: lighten($gray-lighter, 2%);
                padding: 2rem;

                @include media-breakpoint-down(xs) {
                    margin-left: -1rem;
                    margin-right: -1rem;
                    padding: 1rem;
                }

                .choosen-delivery-store{
                    padding: 1.5rem;
                    background-color: #ffffff;
                    box-shadow: 0 0 3px #e5e5e5;
                    border-radius: $border-radius;

                    @include media-breakpoint-down(xs) {
                        margin-top: 1rem;
                    }

                    .woocommerce-checkout-delivery-title{
                        margin-bottom: 1rem;
                    }
                    .woocommerce-checkout-delivery-fields{
                        .form-row{
                            flex-direction: row;
                            align-items: center;
                            margin-bottom: 0;
                            label{
                                margin-bottom: 0;
                                margin-right: 0.2rem;
                                font-weight: $weight-bold;
                                flex: 1;
                            }
                            input{
                                border: 0;
                                padding: 0;
                                flex: 1;
                                &.disabled{
                                    pointer-events: none;
                                }
                            }
                        }
                    }
                }
            }
            .woocommerce-checkout-payment#payment{
                width: auto;
                margin-left: -2rem;
                margin-right: -2rem;
                background-color: $brand-primary;
                border-radius: 0 0 $border-radius $border-radius;

                @include media-breakpoint-down(xs) {
                    margin-left: -1rem;
                    margin-right: -1rem;
                }

                .payment_methods{
                    padding: 1rem 2rem;
                    background-color: $gray-lighter;
                    border-bottom: 0;
                    @include media-breakpoint-down(xs) {
                        padding: 1rem;
                    }
                    .wc_payment_method{
                        .input-radio{
                            display: inline-block !important;
                            vertical-align: middle;
                            margin-right: 5px;
                        }
                        label{
                            margin-bottom: 0;
                            vertical-align: middle;
                        }
                        .payment_box{
                            display: none !important;
                        }
                    }
                }
                .place-order{
                    margin-bottom: 0;
                    padding: 2rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    @include media-breakpoint-down(sm) {
                        flex-wrap: wrap;
                        padding: 2rem 1rem;
                    }
                    br{
                        display: none;
                    }
                    .wc-terms-and-conditions{
                        flex: 1;
                        margin-bottom: 0;
                        padding: 0;
                        @include media-breakpoint-down(sm) {
                            flex: 1 1 100%;
                        }
                        .checkbox{
                            &, a{
                                color: #ffffff;
                            }
                            a{
                                text-decoration: underline;
                            }
                            input[type="checkbox"]{
                                border-color: #ffffff;
                                color: #ffffff;

                                &.active{
                                    background-color: #ffffff;
                                    position: relative;
                                    &:after{
                                        border-color: $brand-primary;
                                    }
                                }
                            }
                        }
                    }
                    .btn#place_order{
                        @include media-breakpoint-down(sm) {
                            margin-bottom: 0;
                        }
                        @include media-breakpoint-down(xs) {
                            flex: 1 1 100%;
                        }
                    }
                }
            }
        }
    }
}

.xdebug-error{
    display: none;
}