h1, h2, h3, h4, h5, h6, 
.h1, .h2, .h3, .h4, .h5, .h6{
    font-family: "Open Sans Condensed", "Helvetica Neue", Arial, sans-serif;
    font-weight: $weight-bold;
}

ul, ol{
    padding-left: 20px;
}

blockquote{
    font-style: italic;
}

hr{
    border-color: currentColor;
}
mark{
    padding: 0 0.3em;
    border-radius: 7px;
    background-color: #fcfac3;
    word-break: keep-all;
    display: inline-block;
}