.table-scroll{
    width: 100%;
    overflow-x: auto;
}
.table{
    margin: 0;
    border-collapse: collapse;
    th, td{
        padding: .7rem 1rem;
        font-weight: $weight-regular;
        border-color: $gray-lighter;

        @include media-breakpoint-down(xs) {
            padding: 0.6rem;
        }
    }
}