.container {
    width: 100%;
    max-width: $max-width;
    min-width: $min-width;
}
.container-wrapper {
    @include size(100%, 100%);
    width: 100%;
    border: 0;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
        padding-top: 86px;
    }
}
.main-content {
    background-color: $body-bg;
    margin-top: 110px;

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
    }
}

.intro-slider {
    padding-top: 20px;
}

body.IE {
    main.main-content {
        padding: 50px 0 240px; 
    }
}

.archive, .search-results, .woocommerce-wishlist, .woocommerce-account, .woocommerce-cart, .woocommerce-checkout {
    .main-content {
        background-color: #fafafa;
        .block{
            background-color: transparent;
        }
    }
}
.search-results{
    .main-layout{
        justify-content: center;
    }
    .main{
        max-width: 980px;
    }
}

.archive, .single-product, .search-results {
    .container{
        max-width: $max-width-larger;
    }
}

.main-layout {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }
    .main {
        padding: 0 15px;
        max-width: 100%;
    }
}
.main {
    padding: 30px 0 15px 0;
    flex: 1 1;
    @include media-breakpoint-down(sm) {
        padding: 0;
    }
    .content{
        padding: 10px 0;
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }
}

/**
 Front page fix for a block
 */
 .custom-image-block {
    @media screen and (max-width: 1320px) {
        background-size: contain;
    }
  
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
