#accordion{  
      
    .card{
        border: 0;
        .card-header{
            position: relative;
            h5{
                font-weight: $weight-light;
            }
            *{
                margin: 0;
                text-decoration: none;
                text-transform: none;
            }
            .accordion-toggle {
                &:after{
                    font-family: FontAwesome;
                    content: '\f068';
                    font-size: 12px;
                    line-height: 12px;
                    position: absolute;
                    right: 0;
                    top: 12px;
                    color: #000
                }
                &.collapsed{
                    &:after{
                        content: '\f067';
                    }
                }
            }
        }
        .card-block{
            padding-top: 10px;
        }
    }

}