.woocommerce-order-received{
    .woocommerce{
        padding: 0;
    }
}

.woocommerce{
    color: #000000;
    .woocommerce-thankyou-section{
        padding: 1.5rem 2rem;
        background-color: $brand-primary;
        border-radius: $border-radius $border-radius 0 0;
        color: #ffffff;

        @include media-breakpoint-down(xs) {
            padding: 1.5rem;
        }

        .woocommerce-notice{
            color: #ffffff;
        }
        .woocommerce-order-overview{
            margin-bottom: 0;
            padding: 1rem 0 0 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            &:before, &:after{
                display: none;
            }
            li{
                float: none;
                padding: 0;
                margin: 0;
                border: 0;

                @include media-breakpoint-down(xs) {
                    flex: 1 1 50%;
                }
            }
        }
    }
    .woocommerce-order-details{
        padding: 2rem;
        @include media-breakpoint-down(xs) {
            padding: 1rem;
        }
    }
    .woocommerce-order-section{
        display: flex;
        margin-bottom: 30px;
        &:last-of-type{
            margin-bottom: 0;
        }
        .column{
            @include media-breakpoint-down(xs) {
                margin-bottom: 1rem;
            }
        }
        .woocommerce-order-box{
            padding: 1.5rem;
            border: 1px solid $gray-lighter;
            height: 100%;

            @include media-breakpoint-down(xs) {
                padding: 1rem;
            }

            h4{
                margin-bottom: 1rem;
            }
        }
    }
}

.woocommerce-view-order{
    .woocommerce-order-details{
        padding: 0;
    }
}