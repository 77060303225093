.slick-slider{
    width: inherit;
    position: relative;
    visibility: hidden;
    &.slick-initialized{
        visibility: visible; 
    }
    .carousel-item{
        @include center-cover();
        img{
            @include size(100%, auto);
        }
    }
}

.slick-arrow{
    @include size(30px, 50px);
    @include position(absolute, 50%, auto, auto, auto);
    transform: translate(0,-50%);
    text-align: center;
    line-height: 50px;
    display: block;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 2;
    font-size: 12px;

    &:before{
        font-family: 'FontAwesome';
        color: $gray-dark;
        font-size: 28px;
        @include opacity(0.7);
    }

    // Arrows 
    &.slick-slide-prev{
        left: 15px;
        &:before{
            content: '\f053';
            padding-right: 2px;
        }
    }
    &.slick-slide-next{
        right: 15px;
        &:before{
            content: '\f054';
            padding-left: 2px;
        }
    }
}

.slick-dots{
    @include position(absolute, auto, 0, 0, 0);
    list-style: none;
    padding: 1rem 0.5rem;
    margin: 0 auto;
    line-height: 0;
    width: 100%;

    @include media-breakpoint-down(sm) {
        padding: 10px 15px 0;
    }

    li{
        display: inline-block;
        margin-right: 4px;
        padding: 0;
        line-height: 0;

        &.slick-active{
            button{
                background-color: darken($brand-primary, 5%);
            }
        }

        button{
            @include size(9px, 10px);
            transform: skewX(-25deg);
            appearance: none;
            background-color: $gray-light;
            outline: 0;
            border: 0;
            padding: 0;
            font-size: 0;
            cursor: pointer;
        }
    }
}