.main-layout .main .products div.product{
    @extend .col-lg-4, .col-md-6, .col-sm-6, .col-12;
}
.products div.product{
    @extend .col-lg-3, .col-md-4, .col-sm-6, .col-12;
    margin: 15px 0;

    @include media-breakpoint-down(xs) {
        margin: 5px 0;
    }

    .yith-wcwl-add-to-wishlist, .clear{
        display: none;
    }
    .product-wrapper{
        border: 4px solid #ffffff;
        display: block;
        text-decoration: none;
        transition: all 0.25s ease-in;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100%;

        @include hover-state {
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
            border-width: 2px;
        }
        @include media-breakpoint-down(xs) {
            flex-direction: row;
        }
        
        > .woocommerce-LoopProduct-link{
            display: flex;
            align-items: stretch;
            flex: 1;
            text-decoration: none;
        }
        .product-image{
            position: relative;
            @include media-breakpoint-down(xs) {
                flex: 0 0 130px;
                width: 130px;
            }
            img{
                width: 100%;
                height: auto !important;
            }
            .image-placeholder{
                @include responsive-ratio(7, 8, true);
                .fa{
                    font-size: 30px;
                }
            }
            .onsale{
                @include position(absolute, 10px, 10px, auto, auto);
                border-radius: 20px;
                background-color: #ffffff;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
                padding: 0.6rem 1.1rem;
                line-height: normal;
                min-height: 0;
                color: red;
            }
            .topselection{
                @include position(absolute, 10px, auto, auto, 0);
                background-color: #000000;
                padding: 0.5rem 0.7rem;
                line-height: normal;
                text-transform: uppercase;
                min-height: 0;
                color: #ffffff;
                font-weight: $weight-bold;
                z-index: 1;
                .fa{
                    padding-right: 5px;
                }
                @include media-breakpoint-down(xs) {
                    .topselection-text{
                        display: none;
                    }
                    .fa{
                        padding-right: 0;
                    }
                }
            }
            @include hover-state {
                .wishlist-overlay{
                    @include opacity(1);
                    transition: all 0.4s ease-in-out;
                    .wishlist-toggle{
                        transform: scale(1.1);
                    }
                }
            }
            .wishlist-overlay{
                @include position(absolute, 0, 0, 0, 0);
                @include opacity(0);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                transition: all 0.3s ease-in-out;
                z-index: 2;

                &:after{
                    @include backdrop-blur($brand-primary-light, $brand-primary-light, 0.5, 0.9);
                    z-index: 1;
                }
                .wishlist-toggle{
                    @include square(32px);
                    color: #ffffff;
                    line-height: 32px;
                    transition: all 0.4s ease-in-out;
                    transform: scale(0.7);
                    position: relative;
                    z-index: 10;
                    cursor: pointer;

                    &:before, &:after{
                        transition: all 0.7s ease-in-out;
                        font-family: 'FontAwesome';
                        font-size: 2rem;
                    }

                    &:before{
                        @include position(absolute, 0, 0, 0, 0);
                        content: '\f08a';
                    }
                    &:after{
                        @include position(absolute, 0, 0, 0, 0);
                        @include opacity(0);
                        transform: scale(0.1);
                        content: '\f004';
                    }

                    &.active{
                        transform: scale(1.3);
                        &:after{
                            @include opacity(1);
                            transform: scale(1);
                            color: red;
                        }
                        &:before{
                            transform: scale(0.5);
                        }
                    }
                }
                .message{
                    padding: 10px 0;
                    display: none;
                    position: relative;
                    z-index: 10;
                    color: #ffffff;
                    transition: none;
                }
            }
        }
        .product-info{
            width: 100%;
            padding: 1.5rem 1rem;
            background-color: #ffffff;
            transition: all 0.25s ease-in;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            
            @include media-breakpoint-down(xs) {
                padding: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: start;
                flex: 1;
                text-align: left;
            }
            .woocommerce-loop-product__title{
                font-family: "aller";
                font-weight: $weight-bold;
                font-size: 1.2rem;
                margin: 0;
            }
            .product-collection{
                padding: 0.2rem 0.5rem;
                color: $gray;
                font-style: italic;
                @include media-breakpoint-down(xs) {
                    padding: 0.2rem 0;
                }
            }
            .price{
                font-family: "Open Sans Condensed";
                color: #000000;
                font-weight: $weight-bold;
                margin: 0;
                .amount{
                    font-size: 1.1rem;
                }
                .woocommerce-Price-currencySymbol{
                    font-size: 0.7rem;
                }

                @include media-breakpoint-down(xs) {
                    margin-top: auto;
                }
            }
            .btn{
                margin: 10px 0;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
}