.modal-backdrop{
    background: transparent;
    &:after{
        @include backdrop-blur($brand-primary-light, darken($brand-primary, 45%), 0.6, 0.5);
        z-index: 1;
    }
}

.modal.fade .modal-dialog {
    transform: scale(0.1);
    opacity: 0;
    transition: transform 0.25s cubic-bezier(1,0.2,0.12,1.3), opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.modal.fade.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}

.modal{
    text-align: center;
    padding: 0 !important;

    .modal-dialog {
        text-align: left;
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        width: 95%;
        .modal-content{
            .close{
                font-size: 25px;
                line-height: 20px;
                font-weight: 100;
            }
            .loading{
                @include square(80px);
                @include position(absolute, 50%, auto, auto, 50%);
                transform: translate(-50%, -50%);
                display: none;
            }
        }
    }
    &:before{
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }
    
}


// Calculate wallpapers modal
.calculate-modal{
    .modal-content{
        border: 0;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        .modal-header{
            background-color: $brand-primary;
            border-radius: 1.35rem 1.35rem 0 0;
            .modal-title{
                color: #ffffff;
            }
            .close{
                @include opacity(1);
                text-shadow: none;
                color: #ffffff;
            }
        }
        .modal-body{
            form{
                .form-group{
                    .form-control{
                        border-color: $gray-light;
                        &:focus{
                            border-color: darken($brand-primary, 10%);
                        }
                    }
                }
            }
        }
    }
}

// Select store modal
.select-store-modal{
    overflow-x: hidden;
    overflow-y: hidden;
    .modal-dialog{
        max-width: 1100px;
        height: auto;
        overflow: auto;
        @include calc(height, "100% - 40px", 90%);
        margin: 20px auto;
        border-radius: $border-radius;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);

        @media screen and (min-height: 750px) and (min-width: 1100px) {
            height: auto;
            .wpsl-preloader{
                min-height: 500px !important;
            }
        }

        .modal-content{
            height: 100%;
            border: 0;
            position: relative;
            max-width: 1100px;
            border-radius: $border-radius;

            .storelocator-module{
                height: 100%;
            }
            .storelocator-module #wpsl-wrap.wpsl-default-filters{
                border-radius: $border-radius;
                height: 100%;
                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                }
            }
            .storelocator-module #wpsl-wrap.wpsl-default-filters #wpsl-result-list #wpsl-result-list-container{
                padding: 2rem;
                @include media-breakpoint-down(xs) {
                    padding: 1.5rem;
                }
            }
            .wpsl-content-box{
                position: relative;
            }
            .storelocator-module #wpsl-wrap.wpsl-default-filters #wpsl-gmap{
                border-radius: 0 $border-radius $border-radius 0;
                overflow: hidden;
                -webkit-transform: translate3d(0px, 0px, 0px);
                -webkit-mask-image: -webkit-radial-gradient(white, black);
                @include clear-responsive-ratio();
                @include media-breakpoint-down(sm) {
                    flex: 1 0 150px;
                    border-radius: $border-radius $border-radius 0 0;
                }
            }
            .storelocator-module #wpsl-wrap.wpsl-default-filters #wpsl-result-list{
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    flex: 8;
                }
            }
            .close{
                @include position(absolute, 0, 0, auto, auto);
                text-shadow: 0;
                opacity: 1;
            }
        }
    }
}

// Mobile menu modal
.mobile-menu-modal{
    .modal-dialog{
        max-width: 400px;
        .modal-content{
            border: 0;
            border-radius: 0;
            background-color: transparent;
            align-items: center;
            position: relative;
            .close{
                @include position(absolute, -80px, auto, auto, auto);
                @include square(40px);
                @include opacity(1);
                border-radius: 50%;
                text-shadow: none;
                z-index: 2;
                transition: all 0.4s ease-in-out;
                transform-origin: center center;
                color: #ffffff;
                font-size: 1.1rem;
                display: flex;
                justify-content: center;
                align-items: center;

                &:after{
                    @include backdrop-blur(#000000, #000000, 0.7, 0.95);
                    border-radius: 50%;
                }
                @include hover-state {
                    transform: scale(1.1);
                }
            }
            .modal-body{
                box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
                border-radius: $border-radius;
                position: relative;
                z-index: 2;
                min-width: 250px;
                padding: 0;
                &:after{
                    @include backdrop-blur(#000000, #000000, 0.7, 0.95);
                    border-radius: $border-radius;
                }

                .navbar-nav{
                    width: 100%;

                    li{
                        margin-left: 0;
                        float: none;
                        position: relative;
                        position: relative;

                        a{
                            font-weight: $weight-medium;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            padding: 0.8rem 1.5rem;
                            text-align: center;
                            display: block;
                            text-decoration: none;
                            font-size: .8rem;
                            color: #ffffff;
                        }

                        &.active, &:hover, &:focus, &:active{
                            > a{
                                border: 0;
                                position: relative;
                                z-index: 2;
                                &:before{
                                    @include backdrop-blur(#000000, #000000, 0.4, 0.7);
                                }
                            }
                        }
                        &:first-child{
                            > a{
                                &, &:before{
                                    border-radius: $border-radius $border-radius 0 0;
                                }
                            }
                        }
                        &:last-child{
                            > a{
                                &, &:before{
                                    border-radius: 0 0 $border-radius $border-radius;
                                }
                            }
                        }

                        &.dropdown{
                            position: relative;
                            a{
                                &:after{
                                    display: none;
                                }
                            }
                            .dropdown-menu{
                                width: 100%;
                                display: none;
                                list-style: none;
                                padding: 0;
                                margin: 0;
                                position: relative;
                                z-index: 2;
                                border-radius: 0;
                                border: 0;
                                background-color: transparent;

                                &:before{
                                    @include backdrop-blur(#000000, #000000, 0.2, 0.5);
                                }
                                
                                .dropdown-item{
                                    text-transform: none;
                                    position: relative;
                                    z-index: 2;
                                    &.active, &:hover, &:focus, &:active{
                                        background-color: transparent;
                                        border: 0;
                                        &:after{
                                            @include backdrop-blur(#000000, #000000, 0.4, 0.7);
                                        }
                                    }
                                }
                                
                                &.active > .sub-menu{
                                    display: block;
                                }
                            }
                            .nav-arrow{
                                @include size(40px, 49px);
                                @include position(absolute, 0, 0, auto, auto);
                                transition: all 0.3s ease;
                                display: block;
                                line-height: 49px;
                                text-align: center;
                                cursor: pointer;
                                z-index: 5;
                                -webkit-backface-visibility: hidden;
                                color: #ffffff;
                                &:after{
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    vertical-align: middle;
                                    border-top: 7px solid;
                                    border-right: 7px solid transparent;
                                    border-left: 7px solid transparent;
                                    display: inline-block;
                                }
                                &.open{
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}

// Notice modal
.notice-modal{
    .modal-content{
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        .modal-body{
            p{
                margin-bottom: 0;
            }
        }
    }
}

// IE popup
.IE-warning {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background: #fff;
    z-index: 99;
    font-weight: 700;
}