$images-path:                   "../images/";

// Colors
$brand-primary:                 #dfbaba;
$brand-primary-light:           lighten(#dfbaba, 20%);
$brand-secondary:               #000000;

$gray-dark:                     #727375;
$gray:                          #a8aaae;
$gray-light:                    #d2d2d3;
$gray-lighter:                  #f5f5f5;
$gray-lightest:                 #fafafa;

// Navs
$navbar-border-radius:          0;

$navbar-light-color:            #000000;
$navbar-light-hover-color:      #000000;
$navbar-light-active-color:     #000000;
$navbar-light-disabled-color:   rgba(#000000,.30);

$navbar-dark-color:             #000000;
$navbar-dark-hover-color:       #000000;
$navbar-dark-active-color:      #000000;
$navbar-dark-disabled-color:    rgba(#000000,.30);

// Body
$body-bg:                       #ffffff;
$body-color:                    #5d5d5d;
$inverse-bg:                    #000000;
$inverse-color:                 #ffffff;                

// Container
$max-width-larger:              1280px;
$max-width:                     1200px;
$min-width:                     300px;

// Global styling
$enable-gradients:              true;
$enable-hover-media-query:      true;

// Buttons
$btn-font-weight:               600;
$btn-box-shadow:                none;
$btn-active-box-shadow:         none;
$btn-padding-y:                 0.7rem;
$btn-padding-x:                 2rem;

$border-radius:                 1.5rem;
$border-radius-lg:              1.5rem;
$border-radius-sm:              1.5rem;

// Tags
$tag-default-bg:                $gray-lighter;
$tag-font-size:                 85%;

// Secondary
$btn-primary-color:             #ffffff;
$btn-primary-bg:                $brand-primary;
$btn-secondary-color:           #ffffff;
$btn-secondary-bg:              $brand-secondary;
$btn-transition:                all .2s ease-in-out;

// Typgraphy
$font-family-sans-serif:        "aller", "Helvetica Neue", Arial, sans-serif;
$headings-color:                #111111;

$font-size-root:                11px;
$font-size-larger:              1.25rem;
$font-size-lg:                  1.125rem;
$font-size-base:                0.9rem;
$font-size-sm:                  .875rem;
$font-size-xs:                  .75rem;

$line-height-base:              1.8;
$font-size-h1:                  1.9rem;
$font-size-h2:                  1.7rem;
$font-size-h3:                  1.4rem;
$font-size-h4:                  1.10rem;
$font-size-h5:                  1.05rem;
$font-size-h6:                  0.9rem;

$weight-light:                  300;
$weight-regular:                400;
$weight-medium:                 500;
$weight-semi-bold:              600;
$weight-bold:                   700;

// Alerts
$alert-link-font-weight:        $weight-medium;
$alert-border-width:            2px;

$alert-success-bg:              transparent;
$alert-info-bg:                 transparent;
$alert-warning-bg:              transparent;

// Modals
$modal-inner-padding:           1rem;
$modal-content-border-color:    rgba(#000000, .1);
$modal-backdrop-bg:             darken($brand-primary, 45%);
$modal-backdrop-opacity:        1;
$modal-header-padding:          1rem;