.pagination{
    padding: 0;
    display: block;
    text-align: center;

    span, a{
        display: inline-block;
        margin-right: 15px;
        padding: 9px 14px 8px 14px;
        font-size: 13px;
        font-weight: $weight-medium;
        text-decoration: none;
        color: $brand-secondary;
        border-radius: 50%;

        @include hover-state {
            color: #ffffff;
            background: $brand-secondary;
        }
    }
    .current{
        background: darken($brand-secondary, 10%);
        color: #fff;
    }
}