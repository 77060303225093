.row-zero{
    margin-left: 0;
    margin-right: 0;
}
.row-zero > .column,
.row-zero > .col{
    padding: 0;
}
.row-five{
    margin-left: -5px;
    margin-right: -5px;
}
.row-five > .column,
.row-five > .col{
    padding: 0 5px;
}
.row-ten{
    margin-left: -10px;
    margin-right: -10px;
}
.row-ten > .column,
.row-ten > .col{
    padding: 0 10px;
}
.row-thirty{
    margin-left: -30px;
    margin-right: -30px;
}
.row-thirty > .column,
.row-thirty > .col{
    padding: 0 30px;
}
.row-fifty{
    margin-left: -50px;
    margin-right: -50px;
}
.row-fifty > .column,
.row-fifty > .col{
    padding: 0 50px;
}