.sidebar{
    padding: 0 15px;
    margin-right: 1.5rem;
    flex: 0 1 280px;
    transition: all 0.3s ease-in;

    @include media-breakpoint-down(sm) {
        flex: 0 1 100%;
        margin-right: 0;
    }

    .sidebar-header{
        padding: 0.8rem 1rem;
        border: 1.5px solid $gray-light;
        background-color: #ffffff;
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0.5rem;
        }

        .sidebar-header-title{
            margin-bottom: 0;
        }
        .sidebar-header-icon{
            @include square(18px);
            @include position(absolute, 50%, 15px, auto, auto);
            transform: translateY(-50%);
            cursor: pointer;

            &:before, &:after{
                background-color: #000000;
                transition: transform 0.25s ease-in, background-color 0.5s ease-in;
                content:"";
            }
            &:before{
                @include size(2px, 100%);
                @include position(absolute, 0, auto, auto, 50%);
                transform: translate(-50%, 0);
            }
            &:after{
                @include size(100%, 2px);
                @include position(absolute, 50%, auto, auto, 0);
                transform: translate(0, -50%);
            }
            &.active{
                &:before, &:after{
                    background-color: #ffffff;
                    margin-top: -1px;
                }
                &:before{
                    transform: rotate(45deg);
                    left: 45%;
                    top: 1px;
                }
                &:after{
                    transform: rotate(45deg);
                }
            }
        }
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .sidebar-body{
        padding: 1rem;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &.open{
        @include position(absolute, 85px, 0, 0, 0);
        background-color: rgba(#000000, 0.4);
        padding: 15px;
        z-index: 100;
        display: flex;
        flex-direction: column;
        justify-content: center;
        pointer-events: none;
        
        .sidebar-header{
            flex: 0 0 44px;
            background-color: $brand-primary;
            border-radius: 0.7rem 0.7rem 0 0;
            border: 0;
            pointer-events: auto;
            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
            }
            .sidebar-header-title{
                color: #ffffff;
            }
        }
        .sidebar-body{
            flex: 1 1 100%;
            background-color: #ffffff;
            border-radius: 0 0 0.7rem 0.7rem;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            pointer-events: auto;
            @include border(0 1px 1px 1px, solid, $gray-light);
        }
    }

    &.fixed{
        position: fixed;
    }

    // Widgets
    .widget{
        @include size(100%, auto);
        padding: 1rem 0;

        &:first-of-type{
            padding-top: 0;
        }
        &:last-of-type{
            padding-bottom: 0;
        }
    }

    .widget_product_search{
        h4{
            display: none;
        }
        .woocommerce-product-search{
            position: relative;
            .search-field{
                width: 100%;
                line-height: 20px;
                border: 0;
                border-radius: 25px;
                padding: 15px 90px 15px 15px;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
            }
            input[type="submit"]{
                @include position(absolute, 2px, 2px, 2px, auto);
                margin-top: 0;
            }
        }
    }

    .widget-woof{
        .woof_container_inner{
            .woof_front_toggle{
                @include size(10px, 20px);
                line-height: 20px;
                font-size: 0;
                position: relative;

                &:after{
                    @include position(absolute, 0, 0, 0, 0);
                    font-family: 'FontAwesome';
                    font-size: 0.8rem;
                }
                &.woof_front_toggle_opened{
                    &:after{
                        content: "\f068";
                    }
                }
                &.woof_front_toggle_closed{
                    &:after{
                        content: "\f067";
                    }
                }
            }
            .woof_block_html_items{
                .woof_list.woof_list_checkbox{
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0;
                    margin-right: -7px !important;
                    margin-left: -7px !important;

                    li{
                        display: flex !important;
                        flex: 0 1 50%;
                        margin: 0 !important;
                        padding: 7px !important;

                        .woof_checkbox_label{
                            @include text-ellipsis(1, 17px);
                            padding: 0;
                            margin: 0;
                            color: #000000;
                            line-height: 1;
                            vertical-align: middle;
                        }
                        input[type="checkbox"]{
                            @include square(17px);
                            -webkit-appearance: button;
                            border: 1px solid $gray-light;
                            background-color: #ffffff;
                            color: $brand-primary;
                            margin-right: 8px;
                            vertical-align: middle;
                            &.active{
                                background-color: #ffffff;
                                border-color: #000000;
                                &:after{
                                    border: solid #000000;
                                    border-width: 0 0.2em 0.2em 0;
                                }
                            }
                        }

                    }
                }
            }
        }
        .woof_submit_search_form_container{
            margin: 1rem 0 0 0;
            .woof_reset_search_form{
                width: 100%;
                font-size: 0.9rem;
                text-transform: uppercase;
                border: 0;
                outline: 0;
                border-radius: $border-radius;
                cursor: pointer;
                transition: all 0.3s ease-in;
                padding: .8rem 1.8rem;
                text-transform: uppercase;
                background-color: $brand-primary;
                color: #ffffff;
                @include hover-state {
                    background-color: darken($brand-primary, 5%);
                    color: #ffffff;
                }
            }
        }
    }
}

.woof_container_inner_kollektion, .woof_container_inner_topselection, .woof_container_inner_varumrke {
    li {
        flex: 0 1 100% !important;
    }
}

div.plainoverlay{
    @include position(fixed, 0, 0, 0, 0);
    @include square(100%);
    background: transparent !important;
    opacity: 1 !important;
    &:after{
        @include backdrop-blur($brand-primary-light, darken($brand-primary, 45%), 0.6, 0.5);
        z-index: 1;
    }
}
div#woof_svg_load_container{
    @include square(100px);
     &:before{
        @include position(absolute, 0, 0, 0, 0);
        @include square(100px);
        @include opacity(1);
        animation: rotating 1.1s linear infinite;
        transform-origin: center center;
        font-family: 'FontAwesome';
        content: "\f110";
        line-height: 100px;
        text-align: center;
        font-size: 100px;
        color: #ffffff;
        transition: all 0.3s ease-in;
    }
    img{
        display: none;
    }
}