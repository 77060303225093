.woocommerce-cart, .woocommerce-checkout{
    .woocommerce{
        .wc-wrapper{
            display: flex;
            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
            }
        }
        .woocommerce-message{
            margin: 1rem;
        }
        .wc-cart-form{
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            flex: 1 1 100%;
            min-height: 450px;
            @include media-breakpoint-down(sm) {
                min-height: 0;
            }
            .woocommerce-cart-wrapper{
                flex: 1;
                @include media-breakpoint-down(sm) {
                    padding: 1rem 0;
                }
                .shop_table{
                    border: 0;
                    margin-bottom: 0;
                    th{
                        color: #000000;
                        padding: 10px 15px;
                    }
                    td{
                        padding: 20px 15px;
                        &.product-quantity{
                            .qty{
                                border-color: $gray-light;
                                border-radius: $border-radius;
                            }
                        }
                        @include media-breakpoint-down(sm) {
                            border-top: 0;
                            padding: 5px 15px;
                        }
                    }
                }
            }
            .woocommerce-cart-form-footer{
                display: flex;
                align-items: center;
                flex: 0 0 60px;
                background-color: $gray-lighter;
                border-radius: 0 0 0 $border-radius;
                padding: 0 15px;

                @include media-breakpoint-down(md) {
                    padding: 15px;
                    border-radius: 0;
                }

                .actions{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    @include media-breakpoint-down(xs) {
                        flex-wrap: wrap;
                    }
                    .coupon{
                        display: flex;
                        margin-right: 10px;

                        @include media-breakpoint-down(xs) {
                            flex: 1 1 100%;
                            margin-bottom: 10px;
                            margin-right: 0;
                            flex-wrap: wrap;
                        }
                        .input-text{
                            flex: 1;
                            @include border(1px 0 1px 1px, solid, darken($gray-lighter, 5%));
                            border-radius: $border-radius 0 0 $border-radius;
                            padding: 5px 10px;
                            line-height: normal;
                            @include media-breakpoint-down(xs) {
                                border-width: 1px;
                                border-radius: $border-radius;
                                flex: 1 1 100%;
                                margin-bottom: 10px;
                            }
                        }
                        .btn{
                            border-radius: 0 $border-radius $border-radius 0;
                            flex: 0;
                            @include media-breakpoint-down(xs) {
                                flex: 1 1 100%;
                                border-radius: $border-radius;
                            }
                        }
                    }
                    .btn{
                        margin: 0;
                        @include media-breakpoint-down(xs) {
                            flex: 1 1 100%;
                        }
                    }
                }
            }
        }
        .cart-collaterals{
            background-color: $brand-primary;
            border-radius: 0 $border-radius $border-radius 0;
            color: #ffffff;
            flex: 0 0 300px;
            display: flex;
            align-items: stretch;
            padding: 2rem;

            @include media-breakpoint-down(md) {
                flex: 1 1 100%;
                border-radius: 0 0 $border-radius $border-radius;
            }
            @include media-breakpoint-down(xs) {
                padding: 1.5rem 1rem;
            }

            .cart_totals{
                display: flex;
                flex-direction: column;
                float: none;
                width: 100%;
                max-width: 400px;
                h2{
                    color: #ffffff;
                    margin-bottom: 1rem;
                }
                .shop_table{
                    border: 0;
                    th{
                        padding-left: 0;
                    }
                    td{
                        padding: 9px 0;
                        text-align: right;
                        @include media-breakpoint-down(sm) {
                            background-color: transparent;
                        }
                        .woocommerce-shipping-calculator{
                            display: none;
                        }
                    }
                    #shipping_method{
                        li{
                            padding: 0 0 0 22px;
                        }
                    }
                }
                .wc-proceed-to-checkout{
                    width: 100%;
                    margin-top: auto;
                    text-align: center;
                    padding: 0;
                    .btn{
                        width: 100%;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}