// SVG Icons
.icon{
    @include square(1em);
    display: inline-block;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    position: relative;
}

// CSS Icons
.icon-plus{
    &:before, &:after{
        background-color: currentColor;
        content:"";
    }
    &:before{
        @include size(1px, 100%);
        @include position(absolute, 0, auto, auto, 50%);
        transform: translate(-50%, 0);
    }
    &:after{
        @include size(100%, 1px);
        @include position(absolute, 50%, auto, auto, 0);
        transform: translate(0, -50%);
    }
}
.icon-close{
    &::before, &::after{
        @include position(absolute, 50%, auto, auto, 0);
        @include size(100%, 2px);
        background-color: currentColor;
        margin-top: -1px;
        content: '';
    }
    &::before{
        transform: rotate(45deg);
    }
    &::after{
        transform: rotate(-45deg);
    }
}

.icon-search{
    &::before, &::after{
        content: "";
        @include position(absolute, 0, 0, 0, 0);
        margin: auto;
        transition: border-width .25s ease-in-out, border .25s ease-in-out, width .25s ease-in-out, height .25s ease-in-out, transform .25s ease-in-out, border-radius .25s ease-in-out, background-color 0 ease;
    }
    &::before{
        @include square(0.9rem);
        border-radius: 50%;
        border: 1px solid currentColor;
        transform: translate(-2px, -2px) rotate(45deg);
    }
    &::after{
        @include size(6px, 1px);
        background-color: currentColor;
        transform: translate(5px, 5px) rotate(45deg)
    }
}